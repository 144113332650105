.card {
	padding: 40px;
	width: 570px;
	flex-shrink: 0;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 20px;
	background-color: var(--color-background-main);

	@media (width <= 767px) {
		width: 350px;
	}
}

.text,
.autor {
	margin: 30px 0 0;
}

.text {
	line-height: 22px;
}

.autor {
	font-size: 14px;
	color: var(--color-text-dark-liver);
}
