.box {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 950px;
	max-height: 95vh;
	overflow-x: hidden;
	border-radius: 8px;
	background-color: var(--color-background-white-primary);
	transform: translate(-50%, -50%);
}

.innerBox {
	padding: 22px 55px;
	max-height: 95vh;
	overflow-y: auto;
}

.closeButton {
	position: absolute !important;
	right: 17px;
	top: 17px;
}

.form {
	padding-top: 30px;
}

.formList {
	display: grid;
	grid-template-columns: repeat(2, 48%);
	gap: 3px 4%;
}

.firstCallItem {
	padding-top: 7px;
	align-self: center;
}

.buttonWrapper {
	margin-top: 34px;
	display: flex;
	justify-content: space-between;
}

.saveCancelButtonWrapper {
	display: flex;
	column-gap: 24px;
}
