.section {
	padding-top: 30px;
	background-color: var(--color-background-main);
}

.wrapper {
	display: grid;
	column-gap: 50px;
	grid-template-columns: auto 1fr;

	@media (width <= 1199px) {
		column-gap: 40px;
	}

	@media (width <= 991px) {
		grid-template-columns: auto;
		row-gap: 70px;
	}

	@media (width <= 767px) {
		row-gap: 30px;
	}
}

.title {
	position: absolute;
	margin: -1px;
	padding: 0;
	width: 1px;
	height: 1px;
	clip-path: inset(100%);
	overflow: hidden;
	border: 0;
	white-space: nowrap;
	clip: rect(0 0 0 0);
}

.photoWrapper {
	position: relative;

	@media (width <= 991px) {
		justify-self: center;
		grid-row: 2 / 3;
	}
}

@keyframes bganimeUltimate {
	0%,
	100% {
		border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
		transform: translate3d(0, 0, 0) rotateZ(0.01deg);
	}

	34% {
		border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
		transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
	}

	50% {
		transform: translate3d(0, 0, 0) rotateZ(0.01deg);
	}

	67% {
		border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
		transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
	}
}

.animatedBg {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	margin-right: auto;
	margin-left: auto;
	height: 70%;
	border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
	background: #665f5b;
	animation: bganimeUltimate 5s linear infinite;
	will-change: border-radius, transform;
}

.photo {
	position: relative;
	width: 424px;
	height: 530px;
	min-width: 424px;
	min-height: 530px;

	@media (width <= 1199px) {
		width: 339px;
		height: 424px;
		min-width: 339px;
		min-height: 424px;
	}

	@media (width <= 991px) {
		width: 439px;
		height: 549px;
		min-width: 439px;
		min-height: 549px;
	}

	@media (width <= 767px) {
		width: 300px;
		height: 375px;
		min-width: 300px;
		min-height: 375px;
	}
}

.textWrapper {
	padding-top: 70px;

	@media (width <= 1199px) {
		padding-top: 30px;
	}

	@media (width <= 991px) {
		padding-top: 10px;
		text-align: center;
	}

	@media (width <= 767px) {
		padding-top: 0;
	}
}

.headline {
	margin: 0;
	font-weight: 700;
	text-transform: uppercase;
	color: var(--color-text-main-brown);
}

.name {
	margin: 0;
	font-weight: 800;
	font-size: 64px;
	color: var(--color-text-wenge);

	@media (width <= 1199px) {
		font-size: 53px;
	}

	@media (width <= 991px) {
		font-size: 50px;
	}

	@media (width <= 767px) {
		font-size: 40px;
	}
}

.firstSlogan,
.secondSlogan,
.thirdSlogan {
	margin: 0;
}

.firstSlogan {
	margin-top: 30px;
}

.secondSlogan {
	margin-top: 14px;
}

.thirdSlogan {
	margin-top: 20px;
	font-weight: 600;
	font-size: 18px;
}

.toBookLink {
	margin-top: 60px;
	padding: 15px 20px;
	width: 220px;
	display: block;
	border: 2px solid var(--color-border-main-brown);
	border-radius: 30px;
	font-weight: 600;
	text-align: center;
	color: var(--color-text-white-primary);
	background-color: var(--color-background-main-brown);

	&:hover {
		border-color: var(--color-border-wenge);
		background-color: var(--color-background-wenge);
	}

	@media (width <= 1199px) {
		margin-top: 30px;
	}

	@media (width <= 991px) {
		margin-right: auto;
		margin-left: auto;
	}

	@media (width <= 991px) {
		margin-top: 25px;
	}
}
