.wrapper {
	display: grid;
	grid-template-columns: 330px 1fr;
	gap: 20px 35px;
}

.titleWrapper {
	display: flex;
	column-gap: 6px;
	align-items: center;
	justify-content: center;
}

.title {
	margin: 0;
	font-size: 33px;
}

.currentDate {
	align-self: center;
}

.nav {
	align-self: start;
}
