html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	overflow-y: scroll;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 16px;
	color: var(--color-text-pure-black);
}

#root {
	min-height: 100vh;
	display: grid;
	grid-template-rows: auto 1fr auto;
}

img {
	height: auto;
	max-width: 100%;
	vertical-align: bottom;
}

ul,
ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

button {
	border: none;
	font: inherit;
	font-size: inherit;
	color: inherit;
	background-color: transparent;
	cursor: pointer;
}

svg {
	vertical-align: bottom;
}

a {
	text-decoration: none;
	color: inherit;
}

.container {
	margin: 0 auto;
	padding: 0 30px;
	min-width: 360px;
	max-width: 1230px;
}
