.section {
	padding: 50px 0;

	@media (width <= 991px) {
		padding: 40px 0;
	}
}

.title {
	margin: 0;
	font-size: 48px;
	text-align: center;

	@media (width <= 991px) {
		font-size: 30px;
	}
}

.wrapper {
	margin-top: 30px;
	padding-top: 20px;
	display: grid;
	column-gap: 100px;
	grid-template-columns: repeat(2, 1fr);
	border-radius: 20px;
	background-color: var(--color-background-main);

	@media (width <= 991px) {
		padding: 20px;
		justify-content: center;
		grid-template-columns: 1fr;
		row-gap: 25px;
	}

	@media (width <= 767px) {
		padding-bottom: 20px;
	}
}

.picture {
	justify-self: center;
}

.photo {
	width: 282px;
	height: 360px;
	min-width: 282px;
	min-height: 360px;

	@media (width <= 991px) {
		width: 380px;
		height: 485px;
		min-width: auto;
		min-height: auto;
	}

	@media (width <= 767px) {
		width: 260px;
		height: 332px;
	}
}

.info {
	padding-top: 16px;
}

.subtitle {
	margin: 0;
	font-weight: 500;
	font-size: 18px;

	@media (width <= 991px) {
		text-align: center;
	}
}

.contactList {
	margin-top: 34px;

	@media (width <= 991px) {
		margin-top: 25px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.contactItem {
	+ .contactItem {
		margin-top: 11px;
	}

	&:last-child {
		margin-top: 34px;

		@media (width <= 991px) {
			margin-top: 25px;
			text-align: center;
		}
	}
}

.contactItemTitle {
	margin: 0;

	+ .contactLink {
		margin-top: 11px;
	}
}

.contactLink {
	display: inline-flex;
	column-gap: 6px;
	align-items: center;
	vertical-align: bottom;

	&:hover {
		opacity: 0.7;
	}
}

.socialList {
	margin-top: 45px;
	display: flex;
	
	& > li {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	@media (width <= 991px) {
		margin-top: 30px;
		justify-content: center;
	}
}

.socialLink {
	padding: 8px;
	display: inline-block;

	&:hover {
		opacity: 0.7;
	}
}
