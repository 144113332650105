.fieldLabel {
	margin: 0;
	margin-bottom: 6px;
}

.select {
	&:global(.Mui-focused .MuiOutlinedInput-notchedOutline) {
		border-color: var(--color-border-indian-yellow) !important;
	}
}

.textField {
	& :global(.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline) {
		border-color: var(--color-border-indian-yellow);
	}
}

.phoneInput {
	width: 100% !important;
	transition: none !important;

	&:focus {
		border-color: var(--color-border-indian-yellow) !important;
		box-shadow: 0 0 0 1px #eca555 !important;
	}
}
