.section {
	padding: 70px 0;
	background-color: var(--color-opacity-main-backgound);

	@media (width <= 991px) {
		padding: 60px 0;
	}
}

.wrapper {
	margin: auto;
	margin-top: 40px;
	max-width: 970px;
	display: grid;
	grid-template-columns: 1fr 225px;

	@media (width <= 991px) {
		margin-top: 20px;
		grid-template-columns: 1fr;
	}
}

.title {
	margin: 0;
	font-size: 48px;
	text-align: center;

	@media (width <= 991px) {
		font-size: 30px;
	}
}

.subtitle {
	margin: 0;
	font-weight: 700;
	font-size: 28px;

	@media (width <= 991px) {
		font-size: 20px;
		text-align: center;
	}
}

.leadText {
	margin: 0;
	margin-top: 20px;
}

.footnote {
	font-size: 12px;
	line-height: 15px;
	opacity: 0.8;
}

.form {
	margin-top: 20px;
}

.fieldList {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 6px 20px;

	@media (width <= 767px) {
		grid-template-columns: auto;
	}
}

.leftColumn {
	padding: 40px 50px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	background-color: var(--color-background-white-primary);

	@media (width <= 991px) {
		padding: 20px 30px 10px;
		border-top-right-radius: 20px;
		border-bottom-left-radius: 0;
	}
}

.phoneInput {
	width: 100% !important;
	transition: none !important;

	&:focus {
		border-color: #eca555 !important;
		box-shadow: 0 0 0 1px #eca555 !important;
	}
}

.showSurnameItem {
	padding-bottom: 23px;
	align-self: center;
}

.showSurnameInput {
	display: none;

	&:checked + .showSurnameLabel::before {
		background: var(--color-background-indian-yellow);
	}
}

.showSurnameLabel {
	position: relative;
	padding-left: 30px;
	cursor: pointer;

	&::before,
	&::after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		margin-top: auto;
		margin-bottom: auto;
	}

	&::before {
		width: 20px;
		height: 20px;
		border: 1px solid var(--color-border-indian-yellow);
		border-radius: 4px;
		transition: 0.2s;
	}

	&::after {
		width: 33px;
		height: 33px;
		background-image: url("../../../assets/icons/check.svg");
		background-position: 3px 10px;
		background-size: 13px 13px;
		background-repeat: no-repeat;
	}
}

.wrappButton {
	padding-top: 40px;
	display: flex;
	justify-content: space-around;
	gap: 10px 6px;
	flex-wrap: wrap;
}

.disabledLink {
	pointer-events: none; 
    cursor: default;
}

.toBuyService {
	padding: 17px 28px;
	width: 210px;
	height: 56px;
	display: flex;
	column-gap: 5px;
	align-items: center;
	justify-content: center;
	border-radius: 29px;
	font-weight: 600;
	color: var(--color-text-white-primary);
	background-color: var(--color-background-main-brown);

	&:hover {
		border-color: var(--color-border-wenge);
		background-color: var(--color-background-wenge);
	}
}

.selectIcon {
	display: flex;
	align-items: center;
	gap: 0 12px;
}

.submitButton {
	// margin: 40px auto 0;
	padding: 17px 28px;
	width: 210px;
	height: 56px;
	display: flex;
	column-gap: 5px;
	align-items: center;
	justify-content: center;
	border-radius: 29px;
	font-weight: 600;
	color: var(--color-text-white-primary);
	background-color: var(--color-background-indian-yellow);

	&NotValid {
		// color: var(--color-text-dark-liver);
		background-image: none;
		background-color: var(--color-background-main-brown);
		cursor: auto;

		&:hover {
			background-image: none;
			background-color: var(--color-background-spanish-gray);
		}
	}

	&:hover {
		background-color: var(--color-background-wenge);
	}
}

.loadingImg {
	width: 22px;
}

.privacyPolicyText {
	text-align: center;

	@media (width <= 767px) {
		margin-right: -9px;
		margin-left: -9px;
	}
}

.privacyPolicyLink {
	text-decoration: underline;
	color: var(--color-text-indian-yellow);
}

.rightColumn {
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	text-align: center;
	background-color: var(--color-background-wenge);
	row-gap: 15px;

	@media (width <= 991px) {
		padding: 15px 30px;
		column-gap: 5px;
		flex-direction: row;
		border-top-right-radius: 0;
		border-bottom-left-radius: 20px;
	}

	@media (width <= 767px) {
		flex-direction: column;
		row-gap: 0;
	}
}

.lockIcon {
	width: 81px;
	height: 83px;

	@media (width <= 991px) {
		transform: scale(0.76);
	}

	@media (width <= 890px) {
		width: 16%;
	}

	@media (width <= 650px) {
		width: 20%;
	}

	@media (width <= 530px) {
		width: 30%;
	}

	@media (width <= 380px) {
		width: 40%;
	}
}

.infoText {
	margin: 0;
	color: var(--color-text-white-primary);
}
