.section {
	padding: 50px 0 25px;

	@media (width <= 991px) {
		padding: 40px 0 15px;
	}
}

.wrapper {
	padding-top: 40px;
	display: grid;
	column-gap: 50px;
	grid-template-columns: auto 1fr;

	@media (width <= 1199px) {
		column-gap: 40px;
	}

	@media (width <= 991px) {
		padding-top: 30px;
		grid-template-columns: auto;
		row-gap: 40px;
	}
}

.infoWrapper {
	@media (width <= 991px) {
		margin: auto;
		width: 439px;
	}

	@media (width <= 767px) {
		width: 300px;
	}
}

.photo {
	width: 424px;
	height: 524px;
	min-width: 424px;
	border-radius: 30px;

	@media (width <= 1199px) {
		width: 339px;
		height: 419px;
		min-width: 339px;
		min-height: auto;
	}

	@media (width <= 991px) {
		width: 439px;
		height: 542px;
		min-width: auto;
		min-height: auto;
	}

	@media (width <= 767px) {
		width: 300px;
		height: 370px;
	}
}

.cardWrapper {
	margin-top: 26px;
	display: flex;
	justify-content: space-between;

	@media (width <= 1199px) {
		flex-direction: column;
		align-items: center;
		row-gap: 15px;
	}

	@media (width <= 991px) {
		flex-direction: row;
	}

	@media (width <= 767px) {
		flex-direction: column;
		align-items: center;
		row-gap: 15px;
	}
}

.card {
	padding: 10px 0;
	width: 204px;
	border: 1px dashed var(--color-border-main-brown);
	border-radius: 20px;
	background-color: var(--color-background-smoky-white);
}

.cardNumber,
.cardNumberInfo {
	margin: 0;
	text-align: center;
}

.cardNumber {
	font-size: 40px;
	line-height: 47px;

	@media (width <= 767px) {
		font-size: 28px;
	}
}

.cardNumberInfo {
	font-size: 15px;
}

.title {
	margin: 0;
	font-size: 48px;
	text-align: center;

	@media (width <= 991px) {
		font-size: 40px;
	}

	@media (width <= 767px) {
		font-size: 30px;
	}
}

.firstParagraph {
	margin: 0;
	font-size: 18px;

	@media (width <= 991px) {
		text-align: center;
	}
}

.typeList {
	margin-top: 10px;

	@media (width <= 991px) {
		margin: 20px auto 0;
		max-width: 320px;
	}
}

.dropDownBlock {
	display: grid;
	grid-template-rows: 0fr;
	overflow: hidden;
	transition: grid-template-rows 1s;

	&.openDropDownBlock {
		grid-template-rows: 1fr;
	}
}

.typeList,
.additionalEducationList1,
.additionalEducationList2,
.queriesList {
	padding-left: 22px;
	list-style: disc;
}

.additionalEducationList2,
.queriesList {
	min-height: 0;
}

.educationTitle,
.additionalEducationTitle,
.queriesTitle {
	margin: 30px 0 0;
	font-size: 20px;

	@media (width <= 991px) {
		text-align: center;
	}
}

.educationDesc {
	margin: 10px 0 0;

	@media (width <= 991px) {
		text-align: center;
	}
}

.queriesSubtitle {
	margin: 10px 0 0;
	font-weight: 600;

	@media (width <= 991px) {
		text-align: center;
	}
}

.moreButton {
	padding: 4px 0;
	display: block;
	font-size: 14px;
	text-decoration: underline;
	color: var(--color-text-dark-liver);

	&:hover {
		opacity: 0.7;
	}

	@media (width <= 991px) {
		margin: auto;
	}
}
