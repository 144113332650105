.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: var(--color-text-white-primary);
	background-color: #282c34;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

:root {
	--color-background-white-primary: #fff;
	--color-background-main: #efeae4;
	--color-background-smoky-white: #f1f1f1;
	--color-background-blaze-orange: #fb6900;
	--color-background-medium-sea-green: #33b679;
	--color-background-indian-yellow: #eca555;
	--color-background-main-brown: #c69d6f;
	--color-background-wenge: #665f5b;
	--color-background-pure-black: #000;
	--color-opacity-pastel-yellow: rgb(236 165 85 / 25%);
	--color-opacity-black: rgb(0 0 0 / 80%);
	--color-opacity-main-backgound: rgb(239 234 228 / 33%);
	--color-text-white-primary: #fff;
	--color-text-indian-yellow: #eca555;
	--color-text-main-brown: #c69d6f;
	--color-text-wenge: #665f5b;
	--color-text-dark-liver: #4f4d4b;
	--color-text-pure-black: #000;
	--color-border-indian-yellow: #eca555;
	--color-border-main-brown: #c69d6f;
	--color-border-wenge: #665f5b;
}
