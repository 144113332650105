.fieldLabel {
	margin: 0;
	margin-bottom: 6px;
}

.textField {
	& :global(.Mui-disabled .MuiOutlinedInput-notchedOutline) {
		border-color: #cacaca;
		background-color: rgb(0 0 0 / 6%);
	}
}

.select {
	&:global(.Mui-focused .MuiOutlinedInput-notchedOutline) {
		border-color: var(--color-border-indian-yellow) !important;
	}

	&:global(.Mui-disabled .MuiOutlinedInput-notchedOutline) {
		border-color: #cacaca;
		background-color: rgb(0 0 0 / 6%);
	}
}

.typeCheckboxWrapper {
	margin-top: 9px;
	display: flex;
	column-gap: 45px;
}

.phoneInput {
	width: 100% !important;
	background-color: rgb(0 0 0 / 6%) !important;
	transition: none !important;
	cursor: auto !important;

	& + :global(.flag-dropdown .selected-flag:focus .arrow) {
		border-top: 4px solid #555;
		border-right: 3px solid transparent;
		border-left: 3px solid transparent;
	}

	&:hover {
		border-color: #cacaca !important;
	}
}
