.section {
	position: relative;
	padding: 60px 0;
	background-color: var(--color-opacity-main-backgound);

	@media (width <= 991px) {
		padding: 50px 0;
	}
}

.title {
	margin: 0;
	font-size: 48px;
	text-align: center;

	@media (width <= 991px) {
		font-size: 30px;
	}
}

.slider {
	position: relative;
}

.viewport {
	position: relative;
	margin-top: 40px;
	height: 271px;
	overflow: hidden;
}

.certificates {
	position: absolute;
	left: 50%;
	top: 0;
	padding: 8px 0;
	display: flex;
	column-gap: 20px;

	&.isAnimationCertificates {
		transition: transform 0.3s ease-out;
	}
}

.buttons {
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	pointer-events: none;

	@media (width <= 767px) {
		position: static;
		margin-top: 18px;
		transform: translateY(0);
	}
}

.buttonWrapper {
	padding: 0 30px;
	display: flex;
	justify-content: space-between;

	@media (width <= 991px) {
		padding: 0 20px;
	}
}

.sliderButton {
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	box-shadow: 0 0 6px 3px rgb(0 0 0 / 35%);
	background-color: var(--color-background-indian-yellow);
	pointer-events: auto;

	&Next {
		transform: rotate(180deg);

		&:active {
			transform: translate(1px, 1px) rotate(180deg);
		}
	}

	&:hover {
		background-color: var(--color-background-wenge);
	}

	&:active {
		transform: translate(1px, 1px);
	}

	@media (width <= 767px) {
		&:hover {
			background-color: var(--color-background-indian-yellow);
		}
	}
}
