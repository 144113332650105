.fieldLabel {
	margin: 0;
	margin-bottom: 6px;
}

.picker {
	& :global(.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline) {
		border-color: var(--color-border-indian-yellow);
	}
}

.select {
	&:global(.Mui-focused .MuiOutlinedInput-notchedOutline) {
		border-color: var(--color-border-indian-yellow) !important;
	}
}

.checkboxWrapper {
	display: flex;
	column-gap: 25px;
}
