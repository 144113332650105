.tableWrapper {
	position: relative;
}

.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%);
}
