.formList {
	padding: 45px 35px;
	display: grid;
	justify-content: center;
	grid-template-columns: 320px 300px;
	gap: 20px 55px;
}

.buttonItem {
	justify-self: center;
	grid-column: 1 / -1;
}
