.footer {
	margin-top: 115px;
	background-color: var(--color-background-main);

	@media (width <= 991px) {
		margin-top: 95px;
	}
}

.wrapper {
	padding: 40px 0;

	@media (width <= 1199px) {
		padding: 30px 0;
	}

	@media (width <= 767px) {
		padding: 20px 0;
	}
}

.infoWrapper {
	display: flex;
	justify-content: space-between;

	@media (width <= 767px) {
		flex-direction: column;
		justify-content: space-around;
		row-gap: 40px;
	}
}

.logoWrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	row-gap: 16px;

	@media (width <= 767px) {
		align-items: center;
	}
}

.copyright {
	margin: 20px 0 0;
}

.socialList {
	display: flex;
	column-gap: 5px;
	align-items: center;
}

.socialLink {
	padding: 4px;
	display: block;
}

.socialLink:hover,
.navLink:hover,
.upButton:hover,
.additionalLink:hover {
	opacity: 0.6;
}

.navWrapper,
.contactsWrapper {
	@media (width <= 1199px) {
		font-size: 15px;
	}

	@media (width <= 767px) {
		text-align: center;
	}
}

.navTitle,
.contactTitle {
	margin: 0;
	font-weight: 600;
}

.navList,
.contactList {
	margin-top: 20px;
}

.navItem + .navItem,
.contactItem + .contactItem {
	margin-top: 8px;
}

.contactLink {
	display: inline-flex;
	column-gap: 8px;
	vertical-align: bottom;

	&:hover {
		opacity: 0.7;
	}
}

.companyInformation {
	margin: 0;
	width: 435px;
	font-size: 13px;
	color: var(--color-opacity-black);

	@media (width <= 1199px) {
		width: 255px;
		font-size: 11px;
	}

	@media (width <= 767px) {
		text-align: center;
	}
}

.additionalWrapper {
	@media (width <= 767px) {
		margin-top: 30px;
		padding-top: 20px;
		display: flex;
		flex-direction: column-reverse;
		border-top: 1px solid rgb(17 17 17 / 20%);
		row-gap: 10px;
	}
}

.upButton {
	margin-left: auto;
	display: block;

	@media (width <= 767px) {
		padding: 0 10px;
	}
}

.additionalLinks {
	margin-top: 10px;
	padding-top: 19px;
	display: flex;
	justify-content: space-between;
	border-top: 1px solid rgb(17 17 17 / 20%);

	@media (width <= 1199px) {
		display: grid;
		justify-items: left;
		grid-auto-flow: column;
		grid-template-columns: repeat(3, auto);
		grid-template-rows: repeat(2, auto);
		row-gap: 10px;
	}

	@media (width <= 767px) {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		border: none;
		row-gap: 12px;
	}
}

.additionalLink {
	font-size: 14px;

	@media (width <= 1199px) {
		font-size: 13px;
	}
}
