.infoBlock {
	padding: 20px 25px;
}

.statusCircle {
	width: 16px;
	height: 16px;
	display: inline-block;
	vertical-align: middle;
	border-radius: 50%;
}

.busyCircle {
	background-color: var(--color-background-blaze-orange);
}

.availableCircle {
	background-color: var(--color-background-medium-sea-green);
}
