.wrapper {
	display: grid;
	grid-template-columns: 330px 1fr;
	gap: 20px 35px;
}

.paper {
	width: 600px;
	justify-self: center;
}

.titleWrapper {
	display: flex;
	column-gap: 6px;
	align-items: center;
	justify-content: center;
}

.title {
	margin: 0;
	font-size: 33px;
}

.subtitle {
	text-align: center;
}

.currentDate {
	align-self: center;
}

.circularProgress,
.subtitle2 {
	position: absolute;
	left: 50%;
	top: 45%;
	transform: translate(-50%);
}
