.headerWrapper {
	position: sticky;
	top: 0;
	z-index: 5;
}

.header {
	position: relative;
	z-index: 5;
	padding: 20px 0;
	box-shadow: 0 3px 6px 0 rgb(0 0 0 / 25%);
	background-color: var(--color-background-white-primary);

	@media (width <= 991px) {
		padding: 8px 0;
	}
}

.wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.logo,
.mobileLogo {
	padding: 0;

	&:hover {
		opacity: 0.65;
	}

	@media (width <= 991px) {
		display: none;
	}
}

.mobileLogo {
	display: none;

	@media (width <= 991px) {
		display: block;
	}
}

.navigation {
	@media (width <= 991px) {
		display: none;
	}
}

.mobileNav {
	position: absolute;
	left: 0;
	right: 0;
	top: -298px;
	z-index: 4;
	padding: 15px 0;
	display: none;
	background-color: var(--color-background-white-primary);
	transition: transform 0.25s;

	&.opened {
		transform: translateY(360px);
	}

	@media (width <= 991px) {
		display: block;
	}
}

.navList {
	display: flex;
	column-gap: 20px;
	align-items: center;
	font-weight: 500;

	@media (width <= 991px) {
		flex-direction: column;
		align-items: stretch;
	}
}

.navItem {
	@media (width <= 991px) {
		display: flex;
		justify-content: center;
	}
}

.navLink {
	position: relative;
	display: block;
	text-decoration: none;
	text-transform: uppercase;
	color: var(--color-text-pure-black);

	&::before {
		content: "";
		position: absolute;
		bottom: -7px;
		width: 0;
		height: 2px;
		background-color: var(--color-background-indian-yellow);
		transition: width 1s;
	}

	&:hover::before {
		width: 100%;
	}

	@media (width <= 991px) {
		margin-right: auto;
		margin-left: auto;
		padding: 16px 0;
	}
}

.hamburgerMenu {
	position: relative;
	width: 45px;
	height: 30px;
	display: none;
	transform: rotate(0deg);
	transition: 0.5s ease-in-out;
	cursor: pointer;

	&:hover .menuLine {
		background-color: var(--color-background-wenge);
	}

	@media (width <= 991px) {
		display: block;
	}
}

.hamburgerMenu .menuLine {
	position: absolute;
	left: 0;
	width: 100%;
	height: 4px;
	display: block;
	border-radius: 9px;
	background-color: var(--color-background-indian-yellow);
	opacity: 1;
	transform: rotate(0deg);
	transform-origin: left center;
	transition: 0.25s ease-in-out;
	transition-property: transform, left, width, opacity;
}

.hamburgerMenu .menuLine:nth-child(1) {
	top: 0;
}

.hamburgerMenu .menuLine:nth-child(2) {
	top: 0;
	bottom: 0;
	margin: auto;
}

.hamburgerMenu .menuLine:nth-child(3) {
	bottom: 0;
}

.hamburgerMenu.open .menuLine:nth-child(1) {
	left: 4px;
	transform: rotate(45deg) translateY(-4px);
}

.hamburgerMenu.open .menuLine:nth-child(2) {
	width: 0%;
	opacity: 0;
}

.hamburgerMenu.open .menuLine:nth-child(3) {
	left: 4px;
	transform: rotate(-45deg) translateY(4px);
}
