.navList {
	padding: 10px 0;
}

.navItem {
	& + .navItem {
		margin-top: 7px !important;
	}

	&:global(.Mui-selected) {
		background-color: var(--color-opacity-pastel-yellow) !important;
	}
}
