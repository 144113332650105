.section {
	padding: 50px 0;
	text-align: center;

	@media (width <= 991px) {
		padding: 35px 0;
	}
}

.wrapper {
	padding: 40px 0;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 20px;
	background-color: var(--color-background-main);

	@media (width <= 991px) {
		padding: 35px 10px;
	}

	@media (width <= 991px) {
		padding: 25px 10px;
	}
}

.title {
	margin: auto;
	max-width: 543px;
	font-weight: 700;
	font-size: 34px;
	color: var(--color-text-main-brown);

	@media (width <= 991px) {
		font-size: 28px;
	}
}

.subtitle {
	margin: 15px 0 0;
	font-weight: 600;
	font-size: 20px;

	@media (width <= 991px) {
		font-size: 18px;
	}
}

.text {
	margin: 15px auto 0;
	max-width: 780px;
	font-size: 17px;
}
