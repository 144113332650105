.section {
	padding: 50px 0;

	@media (width <= 991px) {
		padding: 35px 0;
	}
}

.title {
	margin: 0;
	font-size: 48px;
	text-align: center;

	@media (width <= 991px) {
		font-size: 30px;
	}
}

.subtitle {
	margin: 40px auto 0;
	max-width: 665px;
	font-size: 20px;
	text-align: center;
}

.advantagesList {
	margin-top: 40px;
	display: grid;
	column-gap: 65px;
	grid-template-columns: repeat(3, 1fr);

	@media (width <= 1199px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (width <= 767px) {
		grid-template-columns: 1fr;
	}
}

.advantageItem {
	display: grid;
	column-gap: 15px;
	align-items: center;
	grid-template-columns: auto 1fr;

	@media (width <= 1199px) {
		padding: 20px 10px;

		&:last-child {
			justify-self: center;
			grid-column: 1 / -1;
		}
	}

	@media (width <= 767px) {
		padding: 10px;
		max-width: 342px;
		justify-self: center;
		grid-template-columns: 102px 1fr;
	}
}

.advantageImage {
	@media (width <= 767px) {
		justify-self: center;
	}
}

.advantageText {
	margin: 0;
	line-height: 26px;
}
