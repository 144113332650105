.section {
	padding: 50px 0;

	@media (width <= 991px) {
		padding: 35px 0;
	}
}

.title {
	margin: 0;
	font-size: 48px;
	text-align: center;

	@media (width <= 991px) {
		font-size: 40px;
	}

	@media (width <= 991px) {
		font-size: 30px;
	}
}

.cardWrapper {
	margin-top: 60px;
	display: flex;
	column-gap: 40px;
	justify-content: center;

	@media (width <= 1199px) {
		margin-top: 47px;
	}

	@media (width <= 991px) {
		margin-top: 90px;
		flex-direction: column;
		align-items: center;
		row-gap: 90px;
	}

	@media (width <= 767px) {
		row-gap: 80px;
	}
}

.card {
	position: relative;
	padding: 140px 30px 30px;
	width: 41.5%;
	display: grid;
	border: 1px dashed var(--color-border-main-brown);
	border-radius: 12px;
	background-color: var(--color-background-smoky-white);

	&:hover {
		box-shadow: 0 0 8px 4px rgb(34 60 80 / 20%);
	}

	@media (width <= 1199px) {
		padding: 104px 25px 25px;
	}

	@media (width <= 991px) {
		padding: 90px 20px 20px;
		width: 55%;
	}

	@media (width <= 767px) {
		padding: 82px 15px 10px;
		width: 300px;
	}
}

.laptopImage,
.diaryImage {
	position: absolute;
	pointer-events: none;
}

.laptopImage {
	left: 0;
	top: -100px;
	width: 262px;

	@media (width <= 1199px) {
		top: -84px;
		width: 210px;
	}

	@media (width <= 991px) {
		top: -62px;
		width: 189px;
	}

	@media (width <= 767px) {
		top: -58px;
		width: 170px;
	}
}

.diaryImage {
	right: 0;
	top: -114px;
	width: 301px;

	@media (width <= 1199px) {
		top: -94px;
		width: 241px;
	}

	@media (width <= 991px) {
		top: -68px;
		width: 217px;
	}

	@media (width <= 767px) {
		top: -65px;
		width: 195px;
	}
}

.cardTitle {
	margin: 0;
	font-weight: 500;
	font-size: 24px;
	text-align: center;

	@media (width <= 991px) {
		margin-top: 15px;
		font-size: 20px;
	}
}

.infoList {
	margin-top: 10px;
	padding-left: 20px;
	list-style: disc;
}

.toBookLink {
	margin-top: 25px;
	padding: 15px 30px;
	width: 154px;
	display: block;
	place-self: end center;
	border: 2px solid var(--color-border-indian-yellow);
	border-radius: 30px;
	font-weight: 600;
	text-align: center;
	color: var(--color-text-white-primary);
	background-color: var(--color-background-indian-yellow);

	&:hover {
		border-color: var(--color-border-wenge);
		background-color: var(--color-background-wenge);
	}

	@media (width <= 991px) {
		margin-top: 15px;
	}
}
