.image {
	width: 363px;
	height: 255px;
	min-width: 363px;
	min-height: 255px;

	&:hover {
		box-shadow: 0 0 6px 3px rgb(0 0 0 / 35%);
	}

	&.vertical {
		width: 179px;
		min-width: 179px;
	}
}
