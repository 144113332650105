.fieldLabel {
	margin: 0;
	margin-bottom: 6px;
}

.picker {
	& :global(.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline) {
		border-color: var(--color-border-indian-yellow);
	}
}

:global(.MuiPickersPopper-root .MuiMultiSectionDigitalClock-root .MuiList-root) {
	width: 105px;
	overflow-y: auto;

	:global(.MuiMenuItem-root) {
		width: 83px;
	}

	:global(.MuiMenuItem-root.Mui-selected) {
		background-color: #ed6c02;
	}
}

:global(.MuiPickersPopper-root .MuiDialogActions-root) {
	justify-content: center;

	& :global(.MuiButtonBase-root) {
		color: #ed6c02;
	}
}

.fieldLabel {
	margin: 0;
	margin-bottom: 6px;
}

.checkboxWrapper {
	display: flex;
	column-gap: 25px;
}
